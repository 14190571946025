/* You can add global styles to this file, and also import other style files */
@import url("assets/css/fonts.css");
@import url("assets/css/style.css");

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
/* You can add global styles to this file, and also import other style files */

:root {
  --blue: #064584;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #064584;
  --secondary: #75d3a5;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --font12: 12px;
  --font14: 14px;
}

.red-color {
  color: var(--red) !important;
}

// common css
body {
  background: #e6edf3 !important;
}

.white-box {
  background-color: #ffffff;
}

// ======================== Buttons css Start =====================

.blue-line-btn {
  border: 1px solid var(--secondary);
  box-sizing: border-box;
  border-radius: 2px;
  text-align: center;
  background-color: transparent;
}

.blue-line-btn:hover {
  background-color: var(--secondary);
  color: #ffffff;
}

.blue-line-ltext {
  font-size: var(--font14);
  font-family: OpenSans-Bold;
  color: var(--secondary);
}

.blue-btn {
  border: 1px solid var(--secondary);
  box-sizing: border-box;
  border-radius: 2px;
  text-align: center;
  background-color: var(--secondary);
  font-size: var(--font14);
  color: #ffffff;
  font-family: OpenSans-Bold;
}

.green-btn {
  border: 1px solid #27ae60;
  box-sizing: border-box;
  border-radius: 2px;
  text-align: center;
  background-color: #27ae60;
  font-size: var(--font14);
  color: #ffffff;
  font-family: OpenSans-Bold;
}

.gray-line-btn {
  border: 1px solid #acacac;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: var(--font14);
  text-align: center;
  background-color: transparent;
  color: #acacac;
  font-family: OpenSans-Bold;
}

.gray-btn {
  border: 1px solid #acacac;
  box-sizing: border-box;
  border-radius: 2px;
  text-align: center;
  background-color: #acacac;
  font-size: var(--font14);
  color: #ffffff;
  font-family: OpenSans-Bold;
}

.red-btn {
  border: 1px solid #eb5757;
  box-sizing: border-box;
  border-radius: 2px;
  text-align: center;
  background-color: #eb5757;
  font-size: var(--font14);
  color: #ffffff;
  font-family: OpenSans-Bold;
}

.dark-gray-btn {
  border: 1px solid #707070;
  box-sizing: border-box;
  border-radius: 2px;
  text-align: center;
  font-size: var(--font14);
  background-color: #707070;
  color: #ffffff;
  font-family: OpenSans-Bold;
}

.h32 {
  height: 32px;
}

.h24 {
  height: 24px;
}

.min-w160 {
  min-width: 160px;
}

.t-btn {
  font-size: var(--font14);
}

// ================== head css start ==================

.head-wrap {
  padding: 20px 0px;
  padding-left: 228px;
  background-color: #e6edf3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
}

.breadcrumb-wrap {
  padding: 0px 15px;
}

.breadcrumb {
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  align-items: center;
}

.breadcrumb-item a {
  font-size: var(--font14);
  color: #707070;
  font-family: OpenSans-Regular;
  line-height: 29px;
}

.breadcrumb-item.active {
  color: var(--primary);
  font-size: var(--font14);
  font-family: Signika-SemiBold;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: ">";
  font-family: OpenSans-Regular;
  color: #707070;
}

.breadcrumb-item {
  display: inline-flex;
  line-height: 28px;
}

// ================== cus check button ===================
.cus-check-btn label {
  margin: 0px;
  position: relative;
  font-size: var(--font14);
  color: #333333;
  font-family: OpenSans-Regular;
  padding-left: 37px;
  cursor: pointer;
}

.cus-check-btn label input {
  display: none;
}

.cus-check-btn label span {
  width: 24px;
  height: 24px;
  border: 2px solid #828282;
  box-sizing: border-box;
  border-radius: 2px;
  display: block;
  position: absolute;
  left: 0px;
  top: -4px;
}

.cus-check-btn label input:checked~span {
  background: var(--primary);
  border: 2px solid var(--primary);
}

.cus-check-btn label input:checked~span::after {
  content: "L";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 1px;
  top: -2px;
  color: #ffffff;
  font-family: OpenSans-Bold;
  transform: scaleX(-1) rotate(-47deg);
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

// ================== mat inputs css start ================
.login-main-wrap {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.login-slide-col {
  max-width: 66%;
  width: 100%;
  flex: 0 0 66%;
  background-color: #e8f5ff;
  position: relative;
}

.login-form-col {
  max-width: 34%;
  width: 100%;
  flex: 0 0 34%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.l-f-block {
  width: 100%;
  max-width: 321px;
  margin: 0 auto;
}

.form-block h5 {
  font-size: 20px;
  color: #000000;
  font-family: Signika-SemiBold;
}

.login-slider {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

.login-top-logo {
  position: relative;
  z-index: 1;
}

.login-top-logo {
  padding: 17px;
}

.login-foot-content {
  width: 100%;
  max-width: 577px;
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

::ng-deep .login-slider .owl-nav {
  position: absolute;
  z-index: 99;
  width: 100%;
  top: 40%;
  left: 0px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

::ng-deep .login-slider .owl-carousel .owl-nav .owl-prev,
::ng-deep .login-slider .owl-carousel .owl-nav .owl-next {
  background: "../assets/images/slide-arrow.png";
  width: 35px;
  height: 35px;
  font-size: 0px;
  border-radius: 50%;
}

::ng-deep .login-slider .owl-carousel .owl-nav .owl-prev {
  margin-left: 40px;
}

::ng-deep .login-slider .owl-carousel .owl-nav .owl-next {
  margin-right: 40px;
}

.login-foot-content h1 {
  font-size: 40px;
  color: #ffffff;
  font-family: Signika-Bold;
  line-height: 44px;
}

.login-foot-content p {
  font-size: 16px;
  color: #ffffff;
  font-family: OpenSans-Regular;
}

// ================== Body routing ==================
.body-routing {
  width: 100%;
  height: calc(100vh - 3.8rem);
  overflow: auto;
  padding: 15px 0px 0px 228px;
  margin-top: 60px;
}

.page-wrap {
  padding: 0px 15px;
}

.top-filter-wrap {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  padding: 10px;
}

.filter-inputs {
  display: flex;
  align-items: center;
}

.filter-inputs input {
  max-width: 415px;
  width: 100%;
  height: 32px;
  background: #ffffff;
  border: 0.564531px solid rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  border-radius: 2px;
  font-size: var(--font14);
  box-sizing: border-box;
  padding: 5px 10px;
  font-family: OpenSans-Regular;
}

.search-btn {
  font-size: var(--font14);
  color: #ffffff;
  font-family: OpenSans-Bold;
  width: 120px;
  height: 32px;
  background: var(--secondary);
  border: 1px solid var(--secondary);
  border-radius: 2px;
  margin-left: 8px;
}

.search-btn:hover {
  background: transparent;
  color: var(--secondary);
}

.search-btn:hover svg path {
  fill: var(--secondary);
}

::-webkit-input-placeholder {
  color: #a3a3a3;
}

:-ms-input-placeholder {
  color: #a3a3a3;
}

::placeholder {
  color: #a3a3a3;
}

.date-head-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fil-inline-input {
  display: flex;
  align-items: center;
  margin-right: 20px;
  flex: auto;
}

.cus-small-input label {
  margin: 0px 0px 0px;
  font-size: var(--font14);
  color: #000000;
  font-family: OpenSans-Regular;
  white-space: nowrap;
  margin-right: 4px;
}

.cus-small-input .form-control {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  font-family: OpenSans-Regular;
  border-radius: 2px;
  font-size: var(--font14);
  padding: 1px 9px;
  box-sizing: border-box;
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #ffffff url("/assets/images/down-arrow.png") no-repeat 92% 50% !important;
}

.refresh-btn button {
  width: 20px;
  height: 20px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: auto;
  padding: 0px;
}

.color-table {
  width: 100%;
}

.color-table tbody tr td {
  font-size: var(--font12);
  color: #333333;
  font-family: OpenSans-Regular;
  padding: 5px 10px;
  border: 0px;
  // max-width: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.color-table thead tr {
  background: var(--primary);
  border: 0px;
}

.color-table thead tr th {
  font-size: var(--font12);
  color: #ffffff;
  font-family: OpenSans-Bold;
  vertical-align: middle;
  line-height: 16px;
  border: 0px;
  padding: 5px 10px;
}

.color-table tbody tr.dx-data-row:nth-of-type(odd) {
  background-color: #ffffff;
}

.color-table tbody tr.dx-data-row {
  background-color: #f1f4f8;
}

.color-table .dx-datagrid-headers tr {
  background: var(--primary);
  border: 0px;
}
app-add-party-form, app-add-order {
  .color-table .dx-datagrid-headers tr {
    background: #dfedff !important;
  }
  .dx-header-row .dx-datagrid-drag-action,
.dx-header-row>td>.dx-datagrid-text-content {
  font-size: var(--font14);
  color: #000000;
  font-family: OpenSans-Bold;
  vertical-align: middle;
  line-height: 16px;
  border: 0px;
  padding: 5px !important;
}
}

.dx-header-row .dx-datagrid-drag-action,
.dx-header-row>td>.dx-datagrid-text-content {
  font-size: var(--font14);
  color: #ffffff;
  font-family: OpenSans-Bold;
  vertical-align: middle;
  line-height: 16px;
  border: 0px;
  padding: 5px !important;
}

.with-ad-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.with-ad-action button {
  background: transparent;
  padding: 0px;
  border: 0px;
}

.t-blue-btn {
  font-family: OpenSans-Bold;
  background: var(--secondary);
  border-radius: 2px;
  border: 1px solid var(--secondary);
  font-size: var(--font14);
  color: #ffffff !important;
  text-decoration: none !important;
  line-height: 20px;
  height: 24px;
}

.t-blue-btn:hover {
  background: transparent;
  border: 1px solid var(--secondary);
  color: var(--secondary) !important;
}

.t-blue-btn:hover svg path {
  fill: var(--secondary);
}

.cus-pagination .pagination .page-link {
  background-color: transparent;
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 2px;
  width: 44px;
  height: 24px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font14);
  color: #828282;
  font-family: OpenSans-Bold;
  margin: 0px 4px;
}

.cus-pagination .pagination .page-item:first-child .page-link,
.cus-pagination .pagination .page-item:last-child .page-link {
  border: 0px;
  width: auto;
  color: var(--primary);
}

.cus-pagination .pagination .page-item:first-child .page-link svg {
  margin-right: 4px;
}

.cus-pagination .pagination .page-item:last-child .page-link svg {
  margin-left: 4px;
}

.cus-pagination .pagination .page-item.active .page-link {
  background: var(--primary);
  color: #ffffff;
  border-color: var(--primary);
}

.edit-btn {
  width: 24px;
  height: 24px;
  padding: 0px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.edit-btn:hover svg path {
  fill: var(--primary);
}

.modal-header {
  background: var(--primary);
  border-radius: 2px;
  padding: 9px 14px;
  box-sizing: border-box;
  height: 35px;
  display: flex;
  align-items: center;
}

.modal-header .modal-title {
  font-size: var(--font14);
  color: #ffffff;
  font-family: OpenSans-Bold;
  margin: 0px;
}

.modal-header .m-close-btn {
  padding: 0px;
  opacity: 1;
  margin: 0px;
  line-height: 0;
}

.alert-msg-body {
  padding: 15px;
}

.modal-content {
  border: 0px;
  border-radius: 2px;
}

.checklist-modal {
  max-width: 700px;
}

.missing-modal {
  max-width: 800px;
}

.summary-modal {
  max-width: 700px !important;
}

.dashboard-modal {
  max-width: 600px !important;
}

@media (min-width: 576px) {
  .modal-dialog.dialog513 {
    max-width: 513px;
    margin: 1.75rem auto;
  }

  .modal-dialog.dialog434 {
    max-width: 434px;
    margin: 1.75rem auto;
  }
}

// =========================== inline input css start =======================
.inline-form {
  max-width: 310px;
  width: 100%;
}

.inline-input {
  display: flex;
  align-items: center;
}

.inline-input {
  display: flex;
  align-items: center;
}

.inline-input label {
  margin-bottom: 0px;
  font-size: var(--font14);
  color: #000000;
  font-family: OpenSans-Bold;
  max-width: 50%;
  width: 100%;
  flex: 50%;
}

.cdk-overlay-container {
  z-index: 1060 !important;
}

.field-icon-box {
  position: relative;
}

.input-sign {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-size: var(--font12) !important;
  color: #707070;
  font-family: OpenSans-Regular;
  font-weight: normal !important;
}

.dx-datagrid {
  background-color: transparent !important;
}

.dx-toolbar-items-container {
  padding: 5px 10px;
  height: 51px !important;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 2px !important;
}

.dx-toolbar-after {
  right: 7px !important;
}

.dx-datagrid .dx-row-alt>td,
.dx-datagrid .dx-row-alt>tr>td {
  background-color: #f1f4f8 !important;
  border: 0px !important;
}

.dx-datagrid .dx-column-lines>td {
  border: 0px !important;
  text-transform: capitalize;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row>td {
  vertical-align: middle;
}

.dx-pager .dx-pages .dx-page {
  width: 44px !important;
  height: 24px !important;
  border: 1px solid #828282 !important;
  box-sizing: border-box !important;
  border-radius: 2px !important;
  padding: 0px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  font-size: var(--font14);
  color: #828282;
  font-family: OpenSans-Bold;
}

.dx-pager .dx-page-sizes .dx-selection,
.dx-pager .dx-pages .dx-selection {
  background: var(--primary) !important;
  border-radius: 2px !important;
  border: 1px solid var(--primary) !important;
  color: #ffffff !important;
}

.dx-pager .dx-pages .dx-navigate-button {
  padding: 0px !important;
}

.dx-page-indexes {
  display: inline-flex !important;
  align-items: center !important;
}

.dx-pager .dx-pages .dx-next-button::before,
.dx-pager .dx-pages .dx-prev-button::before {
  margin-top: 0px !important;
  margin-left: 0px !important;
}

.dx-pager .dx-pages .dx-prev-button::before {
  content: "Previous" !important;
  font-size: var(--font14);
  font-family: OpenSans-Bold !important;
  color: var(--primary) !important;
  width: auto !important;
  line-height: 16px;
  margin-right: 10px;
  position: static;
}

.dx-pager .dx-pages .dx-next-button::before {
  content: "Next" !important;
  font-size: var(--font14);
  font-family: OpenSans-Bold !important;
  color: var(--primary) !important;
  width: auto !important;
  line-height: 16px;
  margin-left: 10px !important;
  position: static;
}

.dx-pager .dx-pages .dx-navigate-button {
  width: auto !important;
}

.dx-pager {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.dx-pager .dx-pages {
  float: none !important;
  text-align: center;
}

.dx-datagrid-content .dx-datagrid-table {
  background-color: #ffffff !important;
}

.mearge-table tr {
  vertical-align: middle;
}

.mearge-cell {
  background-color: #0054a8;
}

.t-b-title {
  background: #bedaff;
  border-radius: 2px;
  padding: 6px 10px;
}

.mearge-table thead tr th {
  font-size: var(--font14);
  color: #ffffff;
  font-family: OpenSans-Bold;
  vertical-align: middle;
  line-height: 16px;
  border: 0px;
  padding: 5px 10px;
  border-left: 2px solid #ffffff;
  white-space: nowrap;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #ffffff;
}

.mearge-table thead tr:first-child th:first-child {
  border-left: 0px;
}

// ======================= s-switch-btn =========================
.s-switch-btn label {
  margin: 0px;
  max-width: 177px;
  width: 100%;
  display: flex;
  align-items: center;
  border: 0.5px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 2px;
  height: 32px;
  position: relative;
  overflow: hidden;
}

.s-switch-btn label input {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0;
  cursor: pointer;
}

.s-switch-btn label span {
  width: 50%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font14);
  color: #bdbdbd;
  font-family: OpenSans-Regular;
  position: relative;
  z-index: 2;
}

.switch-box {
  width: 50%;
  background: var(--secondary);
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  z-index: 1;
  transition: all 0.3s linear 0s;
  transform: translateX(0px);
}

.s-switch-btn label input+.switch-box+span {
  font-family: OpenSans-Bold;
  color: #ffffff;
}

.s-switch-btn label input:checked+.switch-box+span+span {
  font-family: OpenSans-Bold;
  color: #ffffff;
}

.s-switch-btn label input:checked+.switch-box+span {
  font-family: OpenSans-Regular;
  color: #bdbdbd;
}

.s-switch-btn label input:checked~.switch-box {
  transform: translateX(88px);
  transition: all 0.3s linear 0s;
}

// ====================== Sucess modal css ==================
.view-barcode {
  max-width: 700px !important;
}

.s-modal-content {
  text-align: center;
}

.s-modal-content h4 {
  font-size: 32px;
  color: #333333;
  font-family: Signika-Bold;
  margin: 25px 0px 10px;
}

.s-modal-content p {
  margin-bottom: 0px;
  font-size: var(--font14);
  color: #707070;
  font-family: OpenSans-Regular;
  line-height: 22px;
}

.sucess-modal-body {
  min-height: 242px;
}

.ab-btn {
  position: absolute;
  right: 8px;
  top: 5px;
}

// =========================== cus-b-head-table ======================
.static-info h5 {
  font-size: var(--font12);
  color: #000000;
  font-family: OpenSans-Bold;
  margin-bottom: 5px;
}

.static-info span {
  font-size: var(--font12);
  color: #707070;
  font-family: OpenSans-Regular;
}

.order-d-card {
  padding: 10px 17px;
}

.pro-b-head {
  background: var(--primary);
  border-radius: 2px;
  padding: 8px 15px;
}

.pro-b-head h5 {
  font-size: var(--font14);
  color: #ffffff;
  font-family: OpenSans-Bold;
}

.pro-details-body {
  background: #ffffff;
  border-radius: 2px;
  padding: 7px;
}

.cus-b-head-table table {
  width: 100%;
  border: 0.5px solid rgba(112, 112, 112, 0.25);
}

.cus-b-head-table table thead tr {
  background: #dfedff;
}

.cus-b-head-table table tbody tr {
  background: #ffffff;
}

.cus-b-head-table table tbody tr:nth-child(even) {
  background: #f1f4f8;
}

.cus-b-head-table table thead tr th {
  font-family: OpenSans-Bold;
  font-size: var(--font14);
  color: #000000;
  padding: 10px;
}

.cus-b-head-table table tbody tr td {
  font-family: OpenSans-Regular;
  font-size: var(--font14);
  color: #333333;
  padding: 10px;
}

.max-w {
  width: 40%;
}

.mr-l4 {
  margin-left: 4px;
}

.mr-r4 {
  margin-right: 4px;
}

.box-input textarea.form-control {
  height: auto;
}

.green-cell {
  background-color: #f1fff7;
  border: 1px solid #27ae60;
  color: #27ae60 !important;
}

.close {
  opacity: 1;
}

.titel-w-tabel h5 {
  font-size: var(--font14);
  color: #000000;
  font-family: OpenSans-Bold;
}

.dx-datagrid-nowrap .dx-header-row>td>.dx-datagrid-text-content {
  white-space: normal;
  overflow: visible;
  text-align: left;
}

// =========================== select font ======================

.mat-option-text,
.mat-select-placeholder {
  font-family: OpenSans-Regular !important;
}

.lcc-field {
  height: auto !important;
}

.lcc-form-field {
  width: 50%;
}

.cursor {
  cursor: pointer;
}

.revised {
  background-color: #a4cbff !important;
}

.dx-datagrid .dx-row-alt.revised>td,
.dx-datagrid .dx-row-alt.revised>tr>td {
  background-color: #a4cbff !important;
}

.no-wrap-txt {
  white-space: normal !important;
}

.clean-history-tbl {
  tr:nth-child(2) {
    td {
      background-color: #0054a8;
    }
  }

  .dx-header-row>td {
    border: 2px solid #fff !important;
    padding: 2px !important;
  }
}

textarea {
  resize: none !important;
}

.mat-chip.mat-standard-chip {
  background-color: #b8e0ff;
}

.dxc-title {
  text {
    font-size: 14px !important;
    font-family: "OpenSans-Bold" !important;
  }
}

.box-input .mat-input-element:disabled {
  font-size: 12px;
  color: #000000;
}

button[disabled] {
  opacity: 0.5;
}

.summary-list p {
  font-size: 14px;
  font-family: OpenSans-Regular;
  color: #adadad;
}

.thank-you-wrap {
  margin-top: 30px;

  h3 {
    font-family: OpenSans-Bold;
    font-size: 2em;
  }

  p {
    font-family: OpenSans-Regular;
    font-size: 1.5em;
    margin-top: 10px;
  }
}

/****Autocomplete select****/
.ownership-select {
  mat-option {
    font-size: 14px;
    border-bottom: 1px solid #ccc;
  }
}

/****Autocomplete select****/

/****Listing select****/

.listing-select {
  padding: 6px !important;
  background: #f1f4f8 !important;
}

/****Listing select****/

.bg-warning {
  background-color: #fca04c !important;
  font-size: 14px;
  font-family: OpenSans-Bold !important;
}

progressbar {
  height: 25px !important;
}

.pdf-msg {
  color: red !important;
  font-family: OpenSans-Bold !important;
}

.questionaire-chips .mat-standard-chip {
  height: 45px !important;
}

.air-logistics-doc-list .mat-list-item-content {
  justify-content: space-between;
}

.static-page-txt {
  h3 {
    font-family: OpenSans-Bold;
  }

  p {
    font-family: OpenSans-Regular;
    line-height: 2em;
    font-size: 14px;
    text-align: justify;
  }
}

.view-order-btn {
  height: 30px;
}