 
@font-face {
    font-family: Signika-Bold;
    src: url("../fonts/Signika-Bold.ttf");
}
@font-face {
    font-family: Signika-Light;
    src: url("../fonts/Signika-Light.ttf");
}
@font-face {
    font-family: Signika-Medium;
    src: url("../fonts/Signika-Medium.ttf");
}
@font-face {
    font-family: Signika-Regular;
    src: url("../fonts/Signika-Regular.ttf");
}
@font-face {
    font-family: Signika-SemiBold;
    src: url("../fonts/Signika-SemiBold.ttf");
}

/* open sans */
@font-face {
    font-family: OpenSans-Bold;
    src: url("../fonts/OpenSans-Bold.ttf");
}
@font-face {
    font-family: OpenSans-Light;
    src: url("../fonts/OpenSans-Light.ttf");
}
@font-face {
    font-family: OpenSans-Regular;
    src: url("../fonts/OpenSans-Regular.ttf");
}
@font-face {
    font-family: OpenSans-SemiBold;
    src: url("../fonts/OpenSans-SemiBold.ttf");
}

/* Roboto  */
@font-face {
    font-family: Roboto-Regular;
    src: url("../fonts/Roboto-Regular.ttf");
}
@font-face {
    font-family: Roboto-Bold;
    src: url("../fonts/Roboto-Bold.ttf");
}