button,
button:hover,
a,
a:focus,
a:active,
a:hover {
    /* cursor: url("../images/partner/pointer.svg"), pointer !important; */
    cursor: pointer;
    outline: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
}
a,
a:hover {
    text-decoration: none;
}
/* all margin */
.mr0 {
    margin: 0px;
}
.mr10 {
    margin: 10px;
}
.mr20 {
    margin: 20px;
}
.mr30 {
    margin: 30px;
}
.mr40 {
    margin: 40px;
}
.mr50 {
    margin: 50px;
}
.mr60 {
    margin: 60px;
}
.mr70 {
    margin: 70px;
}
.mr80 {
    margin: 80px;
}
.mr90 {
    margin: 90px;
}
.mr100 {
    margin: 100px;
}
/* margin top */
.mr-t0 {
    margin-top: 0px;
}
.mr-t10 {
    margin-top: 10px;
}
.mr-t20 {
    margin-top: 20px;
}
.mr-t30 {
    margin-top: 30px;
}
.mr-t40 {
    margin-top: 40px;
}
.mr-t50 {
    margin-top: 50px;
}
.mr-t60 {
    margin-top: 60px;
}
.mr-t70 {
    margin-top: 70px;
}
.mr-t80 {
    margin-top: 80px;
}
.mr-t90 {
    margin-top: 90px;
}
.mr-t100 {
    margin-top: 100px;
}
/* margin right */
.mr-r0 {
    margin-right: 0px;
}
.mr-r10 {
    margin-right: 10px;
}
.mr-r20 {
    margin-right: 20px;
}
.mr-r30 {
    margin-right: 30px;
}
.mr-r40 {
    margin-right: 40px;
}
.mr-r50 {
    margin-right: 50px;
}
.mr-r60 {
    margin-right: 60px;
}
.mr-r70 {
    margin-right: 70px;
}
.mr-r80 {
    margin-right: 80px;
}
.mr-r90 {
    margin-right: 90px;
}
.mr-r100 {
    margin-right: 100px;
}
/* margin bottom */
.mr-b0 {
    margin-bottom: 0px;
}
.mr-b10 {
    margin-bottom: 10px;
}
.mr-b20 {
    margin-bottom: 20px;
}
.mr-b30 {
    margin-bottom: 30px;
}
.mr-b40 {
    margin-bottom: 40px;
}
.mr-b50 {
    margin-bottom: 50px;
}
.mr-b60 {
    margin-bottom: 60px;
}
.mr-b70 {
    margin-bottom: 70px;
}
.mr-b80 {
    margin-bottom: 80px;
}
.mr-b90 {
    margin-bottom: 90px;
}
.mr-b100 {
    margin-bottom: 100px;
}
/* margin left */
.mr-l0 {
    margin-left: 0px;
}
.mr-l10 {
    margin-left: 10px;
}
.mr-l20 {
    margin-left: 20px;
}
.mr-l30 {
    margin-left: 30px;
}
.mr-l40 {
    margin-left: 40px;
}
.mr-l50 {
    margin-left: 50px;
}
.mr-l60 {
    margin-left: 60px;
}
.mr-l70 {
    margin-left: 70px;
}
.mr-l80 {
    margin-left: 80px;
}
.mr-l90 {
    margin-left: 90px;
}
.mr-l100 {
    margin-left: 100px;
}
/* padding  */
.pd0 {
    padding: 0px;
}
.pd10 {
    padding: 10px;
}
.pd20 {
    padding: 20px;
}
.pd30 {
    padding: 30px;
}
.pd40 {
    padding: 40px;
}
.pd50 {
    padding: 50px;
}
.pd60 {
    padding: 60px;
}
.pd70 {
    padding: 70px;
}
.pd80 {
    padding: 80px;
}
.pd90 {
    padding: 90px;
}
.pd100 {
    padding: 100px;
}
/* padding top */
.pd-t0 {
    padding-top: 0px;
}
.pd-t10 {
    padding-top: 10px;
}
.pd-t20 {
    padding-top: 20px;
}
.pd-t30 {
    padding-top: 30px;
}
.pd-t40 {
    padding-top: 40px;
}
.pd-t50 {
    padding-top: 50px;
}
.pd-t60 {
    padding-top: 60px;
}
.pd-t70 {
    padding-top: 70px;
}
.pd-t80 {
    padding-top: 80px;
}
.pd-t90 {
    padding-top: 90px;
}
.pd-t100 {
    padding-top: 100px;
}
/* padding right */
.pd-r0 {
    padding-right: 0px;
}
.pd-r10 {
    padding-right: 10px;
}
.pd-r20 {
    padding-right: 20px;
}
.pd-r30 {
    padding-right: 30px;
}
.pd-r40 {
    padding-right: 40px;
}
.pd-r50 {
    padding-right: 50px;
}
.pd-r60 {
    padding-right: 60px;
}
.pd-r70 {
    padding-right: 70px;
}
.pd-r80 {
    padding-right: 80px;
}
.pd-r90 {
    padding-right: 90px;
}
.pd-r100 {
    padding-right: 100px;
}
.pd-r170 {
    padding-right: 170px;
}
/* padding bottom */
.pd-b0 {
    padding-bottom: 0px;
}
.pd-b10 {
    padding-bottom: 10px;
}
.pd-b20 {
    padding-bottom: 20px;
}
.pd-b30 {
    padding-bottom: 30px;
}
.pd-b40 {
    padding-bottom: 40px;
}
.pd-b50 {
    padding-bottom: 50px;
}
.pd-b60 {
    padding-bottom: 60px;
}
.pd-b70 {
    padding-bottom: 70px;
}
.pd-b80 {
    padding-bottom: 80px;
}
.pd-b90 {
    padding-bottom: 90px;
}
.pd-b100 {
    padding-bottom: 100px;
}
/* padding left */
.pd-l0 {
    padding-left: 0px;
}
.pd-l10 {
    padding-left: 10px;
}
.pd-l20 {
    padding-left: 20px;
}
.pd-l30 {
    padding-left: 30px;
}
.pd-l40 {
    padding-left: 40px;
}
.pd-l50 {
    padding-left: 50px;
}
.pd-l60 {
    padding-left: 60px;
}
.pd-l70 {
    padding-left: 70px;
}
.pd-l80 {
    padding-left: 80px;
}
.pd-l90 {
    padding-left: 90px;
}
.pd-l100 {
    padding-left: 100px;
}
